import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuthToken } from '../../common/app-provider';
import { toLogin, toUsers, toProjects, toEditProject, toImages, toEditImage } from '../../common/routes';

// Containers
import Users from './users';
import Projects from './projects';
import EditProject from './projects/edit-project';
import Images from './images';
import EditImage from './images/edit-image';
import Nav from './nav';

const App = () => {

  const [ authToken ] = useAuthToken();

  // Rendering 

  if(!authToken) return <Redirect to={toLogin()}/>;

  return(
    <div id="main-container">
      <Switch>
        <Route exact path={toUsers()} component={Users}/>
        <Route exact path={toProjects()}  component={Projects}/>
        <Route exact path={toEditProject()}  component={EditProject}/>
        <Route exact path={toImages()}  component={Images}/>
        <Route exact path={toEditImage()}  component={EditImage}/>
        <Redirect to={toProjects()}/>
      </Switch>
      <Nav/>
    </div>
  );
}

export default App;