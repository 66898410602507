import React, { useEffect, useState } from 'react';
import { Button, Modal, Table , Divider, Card, Tag, Icon, notification, Row, Col } from 'antd';
import Axios from 'axios';
import ReactDragListView from "react-drag-listview";
import { isMobile } from "react-device-detect";
import { Link, withRouter } from 'react-router-dom';
import { toEditProject } from '../../../common/routes';

const Projects = () => {

  // State

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Effects

  useEffect(() => {
    fetchProjects();
  }, []);

  // Network 

  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const { data } = await Axios.get("/projects");
      setProjects(data);
      setIsLoading(false);
    } catch (error) {
    } 
  };

  const deleteProject = async (project) => {
    const index = projects.indexOf(project);
    projects[index].isDeleting = true;
    setProjects([...projects]);

    try {
      await Axios.delete(`/projects/${project._id}`);
      await fetchProjects();
      notification.success({ message: `${project.title} deleted` });
    } catch (error) {
      projects[index].isDeleting = false;
      setProjects([...projects]);
    }  
  };

  const updateProjectOrder = async (fromIndex, toIndex) => {
    try {
      setIsLoading(true);
      await Axios.put("/projects", {fromIndex, toIndex});
      await fetchProjects();
      setIsLoading(false);
    } catch (error) {
    } 
  };

  // Handlers

  const onDeleteProject = (project) => {
    Modal.confirm({
      title: 'Delete project',
      content: `Are you sure you wish to delete ${project.title}?`,
      okText: 'Ok',
      onOk: () => deleteProject(project),
      cancelText: 'Cancel',
    });
  }

  const onDragEnd = async (fromIndex, toIndex) => {
    setIsLoading(true);
    await updateProjectOrder(fromIndex, toIndex);
    setIsLoading(false);
  }

  // Rendering

  const { Column } = Table;

  const extra = <Link to={toEditProject('new')}><Button type="primary">Create</Button></Link>;

  const renderPublishedColumn = (project) => <Tag color={project.published ? "green" : "blue"}>{project.published ? "Published" : "Not Published"}</Tag>;
  
  const renderActionsColumn = (project) => {
    return (
      <span>
        <Link to={toEditProject(project._id)}>
          <Button disabled={project.isDeleting}>Edit</Button>
        </Link>
        <Divider type="vertical" />
        <Button loading={project.isDeleting} onClick={() => onDeleteProject(project)}>Delete</Button>
      </span>
    );
  };

  const renderMoveColumn = (proj, record, idx) => {
    return (
      <div>
        {idx > 0 ? <Icon type="caret-up" onClick = {async () => await onDragEnd(idx, idx - 1)}/> : null}
        {idx < projects.length - 1 ? <Icon type="caret-down" onClick = {async () => await onDragEnd(idx, idx + 1)}/> : null}
      </div>
    );
  }
  
  return (
    <Row justify="center" type="flex">
      <Col xs={24} sm={24} md={24} lg={18}> 
        <Card 
          title="Projects"
          extra={extra} >  
          <ReactDragListView handleSelector="tr" onDragEnd={onDragEnd} >  
            <Table bordered loading={isLoading} dataSource={projects} pagination={false} style={{cursor: "move"}}>
              <Column title="Title" dataIndex="title" key="title" />
              <Column 
                title="Published" 
                render={renderPublishedColumn} />
              <Column title="Creation date" dataIndex="createdAt" key="createdAt" />
              <Column
                title="Actions"
                key="action"
                render={renderActionsColumn} />
              { isMobile &&
                <Column
                  title="Move"
                  key="move"
                  render={renderMoveColumn} />
              }
            </Table>
          </ReactDragListView>  
        </Card>
      </Col>
    </Row>
  );
}

export default withRouter(Projects);
