import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { get, set } from "lodash";
import Axios from "axios";
import { Card, Button, Input, Row, Col, notification, Switch } from "antd";
import { ImageUpload, MultipleLinesInput } from "../../../../components";
import { toEditImage } from "../../../../common/routes";

const EditImage = ({ match: { params }, history: { push } }) => {
  // State

  const [isFetching, setIsFetching] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [image, setImage] = useState({});
  const [imageHasBeenUpdated, setImageHasBeenUpdated] = useState(false);

  // Constants

  const extractImageObject = (objectKey, urlKey) => {
    const object = get(image, [objectKey], null);
    if (object) return object;

    return { url: get(image, [urlKey], null) };
  };

  const isExistingImage = image && image._id;
  const isPublished = image && image.published;
  const titleFr = get(image, ["title", "fr"], "");
  const titleEn = get(image, ["title", "en"], "");
  const descriptionParagraphsFr = get(
    image,
    ["descriptionParagraphs", "fr"],
    []
  );
  const descriptionParagraphsEn = get(
    image,
    ["descriptionParagraphs", "en"],
    []
  );
  const imageObject = extractImageObject("image", "url");
  const isOneCol = get(image, ["isOneCol"], true);

  // Effects

  useEffect(() => {
    const { id } = params;
    if (id && id !== "new") {
      fetchImage(id);
    } else {
      setIsFetching(false);
    }
  }, [params]);

  // Network

  const fetchImage = async (id) => {
    try {
      const { data } = await Axios.get(`/images/${id}`);
      setImage(data);
      setIsFetching(false);
    } catch (error) {}
  };

  // Handlers

  const updateImage = (path, value) => {
    set(image, path, value);
    setImage({ ...image });
    setImageHasBeenUpdated(true);
  };

  const formErrors = () => {
    if (!image)
      return [
        "titleFr",
        "titleEn",
        "descriptionParagraphsFr",
        "descriptionParagraphsEn",
        "image",
      ];

    let errors = [];

    if (!image.title || !image.title.fr || image.title.fr === "")
      errors.push("titleFr");
    if (!image.title || !image.title.en || image.title.en === "")
      errors.push("titleEn");
    if (
      !image.descriptionParagraphs ||
      !image.descriptionParagraphs.fr ||
      image.descriptionParagraphs.fr === []
    )
      errors.push("descriptionParagraphsFr");
    if (
      !image.descriptionParagraphs ||
      !image.descriptionParagraphs.en ||
      image.descriptionParagraphs.en === []
    )
      errors.push("descriptionParagraphsEn");
    if (!extractImageObject("image", "url")) errors.push("image");

    return errors;
  };

  const formIsValid = () => {
    return formErrors().length === 0;
  };

  const onSaveClick = async () => {
    // Should never happen
    if (!image) {
      notification.error({ message: "Oups! Something went wrong" });
      return;
    }

    setIsSubmitted(true);

    if (!formIsValid()) {
      notification.error({ message: "Some required fields are missing" });
      return;
    }

    setIsSaving(true);

    const imageObject = cleanImageObject();

    try {
      const { _id, title } = await saveImage();
      if (imageObject) await uploadImageObject(_id, imageObject);
      setImageHasBeenUpdated(false);
      notification.success({ message: `The image ${title.en} has been saved` });
      push(toEditImage(_id));
    } catch (e) {
    } finally {
      setIsSaving(false);
    }
  };

  // Saving network methods

  const cleanImageObject = () => {
    const object = image["image"];
    delete image["image"];
    if (object) {
      image["url"] = "https://via.placeholder.com/800x800";
    }
    return object;
  };

  const saveImage = async () => {
    if (isExistingImage) {
      const { data } = await Axios.put(`/images/${image._id}`, image);
      return data;
    } else {
      const { data } = await Axios.post(`/images`, image);
      return data;
    }
  };

  const uploadImageObject = async (id, imageObject) => {
    const formData = new FormData();
    formData.append("file", imageObject);
    try {
      await Axios.put(`/images/${id}/uploadImageS3`, formData);
    } catch (e) {}
  };

  const togglePublish = async () => {
    if (!isExistingImage) return;

    setIsSaving(true);
    try {
      const { data } = await Axios.put(`/images/${image._id}`, {
        published: !isPublished,
      });
      setImage(data);
      notification.success({
        message: `The image ${data.title.en} has been ${
          data.published ? "published" : "unpublished"
        }`,
      });
    } catch (e) {}
    setIsSaving(false);
  };

  // Rendering

  const pageTitle = isExistingImage ? "Edit image" : "New image";
  const pageExtra = (
    <div className="page-buttons">
      {isExistingImage && (
        <Button onClick={togglePublish} loading={isSaving}>
          {isPublished ? "Unpublish" : "Publish"}
        </Button>
      )}
      <Button
        type="primary"
        onClick={onSaveClick}
        loading={isSaving}
        disabled={!imageHasBeenUpdated}
      >
        Save
      </Button>
    </div>
  );

  const gutter = 20;
  const errors = formErrors();

  return (
    <div className="edit-page">
      <Row justify="center" type="flex">
        <Col xs={24} sm={24} md={24} lg={18}>
          <Card title={pageTitle} loading={isFetching} extra={pageExtra}>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <div
                  className={
                    isSubmitted && errors.includes("image") ? "has-error" : ""
                  }
                >
                  <label>Image</label>
                  <ImageUpload
                    className="full-width-image-upload"
                    file={imageObject}
                    onChange={(image) => updateImage("image", image)}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={16} lg={16}>
                <Row gutter={gutter}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <div
                      className={
                        isSubmitted && errors.includes("titleFr")
                          ? "has-error"
                          : ""
                      }
                    >
                      <label>Title FR</label>
                      <Input
                        onChange={(e) =>
                          updateImage(["title", "fr"], e.target.value)
                        }
                        value={titleFr}
                        placeholder="Title FR"
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <div
                      className={
                        isSubmitted && errors.includes("titleEn")
                          ? "has-error"
                          : ""
                      }
                    >
                      <label>Title EN</label>
                      <Input
                        onChange={(e) =>
                          updateImage(["title", "en"], e.target.value)
                        }
                        value={titleEn}
                        placeholder="Title EN"
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={gutter}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <div
                      className={
                        isSubmitted &&
                        errors.includes("descriptionParagraphsFr")
                          ? "has-error"
                          : ""
                      }
                    >
                      <label>Description FR</label>
                      <MultipleLinesInput
                        onChange={(values) =>
                          updateImage(["descriptionParagraphs", "fr"], values)
                        }
                        values={descriptionParagraphsFr}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <div
                      className={
                        isSubmitted &&
                        errors.includes("descriptionParagraphsEn")
                          ? "has-error"
                          : ""
                      }
                    >
                      <label>Description EN</label>
                      <MultipleLinesInput
                        onChange={(values) =>
                          updateImage(["descriptionParagraphs", "en"], values)
                        }
                        values={descriptionParagraphsEn}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="vertical-align-label">
                  <label>Is one col:</label>
                  <Switch
                    onChange={(checked) => updateImage(["isOneCol"], checked)}
                    checked={isOneCol}
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(EditImage);
