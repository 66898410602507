import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { get, set } from "lodash";
import Axios from "axios";
import {
  Card,
  Button,
  Divider,
  Input,
  Row,
  Col,
  notification,
  Switch,
  Select,
} from "antd";
import { SketchPicker } from "react-color";
import {
  ImageUpload,
  MultipleColorsPicker,
  MultipleImagesUpload,
  MultipleLinesInput,
  StickyCardHeader,
} from "../../../../components";
import { toEditProject } from "../../../../common/routes";

const EditProject = ({ match: { params }, history: { push } }) => {
  
  // State

  const [isFetching, setIsFetching] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [project, setProject] = useState({});
  const [categories, setCategories] = useState(null);
  const [services, setServices] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  // Constants

  const extractImageObject = (objectKey, urlKey) => {
    const object = get(project, [objectKey], null);
    if (object) return object;

    return { url: get(project, [urlKey], null) };
  };

  const extractImageArray = (arrayKey, urlKey) => {
    return get(project, [arrayKey], null)
      ? get(project, [arrayKey], [])
      : get(project, [urlKey], []).map((url) => ({
          url: `${url}?rand=${Math.random()}`,
        }));
  };

  // General info
  const isExistingProject = project && project._id;
  const isPublished = project && project.published;
  const tintColor = get(project, ["tintColor"], "");
  const backgroundColor = get(project, ["backgroundColor"], "");
  const category = get(project, ["categoryId"])
  const title = get(project, ["title"], "");
  const projectServices = get(project, ["serviceIds"], [])
  const slug = get(project, ["slug"], "");
  const shortDescriptionFr = get(project, ["shortDescription", "fr"], []);
  const shortDescriptionEn = get(project, ["shortDescription", "en"], []);
  const mainImage = extractImageObject("mainImage", "mainImageUrl");
  const isHomePage = get(project, ["isHomePage"], false);

  // Header
  const projectDescriptionParagraphsFr = get(
    project,
    ["projectDescriptionParagraphs", "fr"],
    []
  );
  const projectDescriptionParagraphsEn = get(
    project,
    ["projectDescriptionParagraphs", "en"],
    []
  );
  const challengesDescriptionParagraphsFr = get(
    project,
    ["challengesDescriptionParagraphs", "fr"],
    []
  );
  const challengesDescriptionParagraphsEn = get(
    project,
    ["challengesDescriptionParagraphs", "en"],
    []
  );

  // Mobile
  const hardwareImages = extractImageArray(
    "hardwareImages",
    "hardwareImageUrls"
  );
  const hardwareIsTablet = get(project, ["hardwareIsTablet"], false);

  // Design
  const videoUrl = get(project, ["videoUrl"], "");
  const colorPaletteDescriptionFr = get(
    project,
    ["colorPaletteDescription", "fr"],
    ""
  );
  const colorPaletteDescriptionEn = get(
    project,
    ["colorPaletteDescription", "en"],
    ""
  );
  const colorImage = extractImageObject("colorImage", "colorImageUrl");
  const colorCodes = get(project, ["colorCodes"], []);
  const fontImages = extractImageArray("fontImages", "fontImageUrls");
  const iconImages = extractImageArray("iconImages", "iconImageUrls");
  const appIconImage = extractImageObject("appIconImage", "appIconImageUrl");

  // Website
  const websiteImage = extractImageObject("websiteImage", "websiteImageUrl");
  const websiteDescriptionListFr = get(
    project,
    ["websiteDescriptionList", "fr"],
    []
  );
  const websiteDescriptionListEn = get(
    project,
    ["websiteDescriptionList", "en"],
    []
  );

  // Special
  const specialImage = extractImageObject("specialImage", "specialImageUrl");
  const specialTitleFr = get(project, ["specialTitle", "fr"], "");
  const specialTitleEn = get(project, ["specialTitle", "en"], "");
  const specialDescriptionFr = get(project, ["specialDescription", "fr"], "");
  const specialDescriptionEn = get(project, ["specialDescription", "en"], "");

  // Links
  const appStoreLink = get(project, ["appStoreLink"], "");
  const googlePlayLink = get(project, ["googlePlayLink"], "");
  const websiteLink = get(project, ["websiteLink"], "");

  // Effects

  useEffect(() => {
    const { id } = params;
    fetchCategories();
    fetchServices();
    if (id && id !== "new") {
      fetchProject(id);
    } else {
      setIsFetching(false);
    }
  }, [params]);

  // Network

  const fetchProject = async (id) => {
    try {
      const { data } = await Axios.get(`/projects/${id}`);
      setProject(data);
      setIsFetching(false);
      fetchCategories();
    } catch (error) {
      notification.error({ message: "Woopsi" });
    }
  };

  // Categories

  const fetchCategories = async () => {
    try {
      setCategories(null);
      const { data } = await Axios.get("/categories");
      setCategories(data);
    } catch (error) {
      notification.error({ message: "Woopsi" });
    }
  };

  // Services

  const fetchServices = async () => {
    try {
      setServices(null);
      const { data } = await Axios.get("/services");
      setServices(data);
    } catch (error) {
      notification.error({ message: "Woopsi" });
    }
  };

  // Handlers

  const updateProject = (path, value) => {
    set(project, path, value);
    setProject({ ...project });
    setIsDirty(true);
  };

  const formErrors = () => {
    if (!project)
      return [
        "tintColor",
        "backgroundColor",
        "category",
        "title",
        "slug",
        "shortDescriptionFr",
        "shortDescriptionEn",
        "mainImage",
      ];

    let errors = [];

    if (!project.tintColor) errors.push("tintColor");
    if (!project.backgroundColor) errors.push("backgroundColor");
    if (!project.categoryId || !(categories && categories.some(c => c._id === project.categoryId))) errors.push("category");
    if (!project.title || project.title === "") errors.push("title");
    if (!project.slug || project.slug === "") errors.push("slug");
    if (
      !project.shortDescription ||
      !project.shortDescription.fr ||
      project.shortDescription.fr === ""
    )
      errors.push("shortDescriptionFr");
    if (
      !project.shortDescription ||
      !project.shortDescription.en ||
      project.shortDescription.en === ""
    )
      errors.push("shortDescriptionEn");
    if (!mainImage || !mainImage.url || mainImage.url === "")
      errors.push("mainImage");

    return errors;
  };

  const formIsValid = () => {
    return formErrors().length === 0;
  };

  const onSaveClick = async () => {
    // Should never happen
    if (!project) {
      notification.error({ message: "Oups! Something went wrong" });
      return;
    }

    setIsSubmitted(true);

    if (!formIsValid()) {
      notification.error({ message: "Some required fields are missing" });
      return;
    }

    setIsSaving(true);

    const images = cleanImages();

    try {
      const { _id } = await saveProject();
      await uploadImages(_id, images);
      setIsDirty(false);
      notification.success({ message: `The project ${title} has been saved` });
      push(toEditProject(_id));
    } catch (e) {
      notification.error({ message: "Woopsi" });
    } finally {
      setIsSaving(false);
    }
  };

  // Saving network methods

  const cleanImageObject = (objectKey, urlKey) => {
    const object = project[objectKey];
    delete project[objectKey];
    if (object) {
      project[urlKey] = "https://via.placeholder.com/1500x800";
    }
    return object;
  };

  const cleanImageArray = (arrayKey, urlKey) => {
    const array = project[arrayKey];
    delete project[arrayKey];
    if (array) {
      project[urlKey] = array.map((o) =>
        o.isUpdated ? "https://via.placeholder.com/400X400" : o.url
      );
    }
    return array;
  };

  const cleanImages = () => {
    const mainImage = cleanImageObject("mainImage", "mainImageUrl");
    const hardwareImages = cleanImageArray(
      "hardwareImages",
      "hardwareImageUrls"
    );
    const colorImage = cleanImageObject("colorImage", "colorImageUrl");
    const appIconImage = cleanImageObject("appIconImage", "appIconImageUrl");
    const fontImages = cleanImageArray("fontImages", "fontImageUrls");
    const iconImages = cleanImageArray("iconImages", "iconImageUrls");
    const websiteImage = cleanImageObject("websiteImage", "websiteImageUrl");
    const specialImage = cleanImageObject("specialImage", "specialImageUrl");

    return {
      mainImage,
      hardwareImages,
      colorImage,
      appIconImage,
      fontImages,
      iconImages,
      websiteImage,
      specialImage,
    };
  };

  const saveProject = async () => {
    if (isExistingProject) {
      const { data } = await Axios.put(`/projects/${project._id}`, project);
      return data;
    } else {
      const { data } = await Axios.post(`/projects`, project);
      return data;
    }
  };

  const uploadImages = async (
    _id,
    {
      mainImage,
      hardwareImages,
      colorImage,
      appIconImage,
      fontImages,
      iconImages,
      websiteImage,
      specialImage,
    }
  ) => {
    if (mainImage) {
      await uploadSingleImage(_id, mainImage, "mainImageUrl");
    }
    if (hardwareImages) {
      await uploadMultipleImages(_id, hardwareImages, "hardwareImageUrls");
    }
    if (colorImage) {
      await uploadSingleImage(_id, colorImage, "colorImageUrl");
    }
    if (appIconImage) {
      await uploadSingleImage(_id, appIconImage, "appIconImageUrl");
    }
    if (fontImages) {
      await uploadMultipleImages(_id, fontImages, "fontImagesUrls");
    }
    if (iconImages) {
      await uploadMultipleImages(_id, iconImages, "iconImageUrls");
    }
    if (websiteImage) {
      await uploadSingleImage(_id, websiteImage, "websiteImageUrl");
    }
    if (specialImage) {
      await uploadSingleImage(_id, specialImage, "specialImageUrl");
    }
  };

  const uploadSingleImage = async (id, image, key) => {
    const formData = new FormData();
    formData.append("file", image);
    try {
      await Axios.put(`/projects/${id}/uploadFileS3?name=${key}`, formData);
    } catch (e) {}
  };

  const uploadMultipleImages = async (id, images, key) => {
    const formData = new FormData();
    let indexes = [];

    for (let i = 0; i < images.length; i++) {
      if (images[i].isUpdated) indexes.push(i);
      formData.append("file", images[i]);
    }

    try {
      if (indexes.length > 0)
        await Axios.put(
          `/projects/${id}/uploadMultiplesFilesS3?name=${key}&indexes=${indexes}`,
          formData
        );
    } catch (e) {}
  };

  const togglePublish = async () => {
    if (!isExistingProject) return;

    setIsSaving(true);
    try {
      const { data } = await Axios.put(`/projects/${project._id}`, {
        published: !isPublished,
      });
      setProject(data);
      notification.success({
        message: `The project ${data.title} has been ${
          data.published ? "published" : "unpublished"
        }`,
      });
    } catch (e) {}
    setIsSaving(false);
  };

  // Rendering

  const pageTitle = isExistingProject ? "Edit project" : "New project";
  const pageExtra = (
    <div className="page-buttons">
      {isExistingProject && (
        <Button onClick={togglePublish} loading={isSaving}>
          {isPublished ? "Unpublish" : "Publish"}
        </Button>
      )}
      <Button
        type="primary"
        onClick={onSaveClick}
        loading={isSaving}
        disabled={!isDirty}
      >
        Save
      </Button>
    </div>
  );

  const gutter = 20;
  const errors = formErrors();

  return (
    <div className="edit-page">
      <StickyCardHeader title={pageTitle} extra={pageExtra} />

      <Row justify="center" type="flex">
        <Col xs={24} sm={24} md={24} lg={18}>
          <Card title={pageTitle} loading={isFetching} extra={pageExtra}>

            <h6>General info</h6>

            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={12} lg={8}>
                <div
                  className={
                    isSubmitted && errors.includes("tintColor")
                      ? "has-error"
                      : ""
                  }
                >
                  <label>Color</label>
                  <SketchPicker
                    color={tintColor}
                    onChange={({ hex }) => updateProject("tintColor", hex)}
                  />
                </div>
                <div
                  className={
                    isSubmitted && errors.includes("backgroundColor")
                      ? "has-error"
                      : ""
                  }
                >
                  <label>Background Color</label>
                  <SketchPicker
                    color={backgroundColor}
                    onChange={({ hex }) =>
                      updateProject("backgroundColor", hex)
                    }
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={16}>
                <div
                  className={
                    isSubmitted && errors.includes("category") ? "has-error" : ""
                  }
                >
                  <label>Category</label>
                  <Select
                    showSearch
                    placeholder="Select a category"
                    loading={!categories}
                    optionFilterProp="value"
                    onChange={(e) => updateProject("categoryId", e)}
                    value={category}
                  >
                    {(categories || []).map((cat) => (
                      <Select.Option
                        value={cat._id}
                        title={cat.nameEn}
                        key={cat._id}
                      >
                        {cat.nameEn}  
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div
                  className={
                    isSubmitted && errors.includes("title") ? "has-error" : ""
                  }
                >
                  <label>Title</label>
                  <Input
                    value={title}
                    onChange={(e) => updateProject("title", e.target.value)}
                    placeholder="Enter a title"
                  />
                </div>
                <div>
                  <label>Services</label>
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder="Choose your services"
                    loading={!services}
                    optionFilterProp="value"
                    onChange={(e) => updateProject("serviceIds", e)}
                    value={projectServices}
                  >
                    {(services || []).map((item) => (
                      <Select.Option
                        value={item._id}
                        title={item.nameEn}
                        key={item._id}
                      >
                        {item.nameEn}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div
                  className={
                    isSubmitted && errors.includes("slug") ? "has-error" : ""
                  }
                >
                  <label>Slug</label>
                  <Input
                    value={slug}
                    onChange={(e) => updateProject("slug", e.target.value)}
                    placeholder="Slug"
                  />
                </div>
                <div
                  className={
                    isSubmitted && errors.includes("shortDescriptionFr")
                      ? "has-error"
                      : ""
                  }
                >
                  <label>Short Description FR</label>
                  <Input
                    onChange={(e) =>
                      updateProject(["shortDescription", "fr"], e.target.value)
                    }
                    value={shortDescriptionFr}
                    placeholder="Entrer une short description FR"
                  />
                </div>
                <div
                  className={
                    isSubmitted && errors.includes("shortDescriptionEn")
                      ? "has-error"
                      : ""
                  }
                >
                  <label>Short Description EN</label>
                  <Input
                    onChange={(e) =>
                      updateProject(["shortDescription", "en"], e.target.value)
                    }
                    value={shortDescriptionEn}
                    placeholder="Enter a short description EN"
                  />
                </div>
                <div className="vertical-align-label">
                  <label>Is on home page:</label>
                  <Switch
                    onChange={(checked) =>
                      updateProject(["isHomePage"], checked)
                    }
                    checked={isHomePage}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div
                  className={
                    isSubmitted && errors.includes("mainImage")
                      ? "has-error"
                      : ""
                  }
                >
                  <label>Main image</label>
                  <ImageUpload
                    className="full-width-image-upload"
                    file={mainImage}
                    onChange={(image) => updateProject("mainImage", image)}
                  />
                </div>
              </Col>
            </Row>

            <Divider />

            <h6>Header</h6>

            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className={isSubmitted ? "has-error" : ""}></div>
              </Col>
            </Row>

            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div>
                  <label>Project description FR</label>
                  <MultipleLinesInput
                    onChange={(values) =>
                      updateProject(
                        ["projectDescriptionParagraphs", "fr"],
                        values
                      )
                    }
                    values={projectDescriptionParagraphsFr}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div>
                  <label>Project description EN</label>
                  <MultipleLinesInput
                    onChange={(values) =>
                      updateProject(
                        ["projectDescriptionParagraphs", "en"],
                        values
                      )
                    }
                    values={projectDescriptionParagraphsEn}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div>
                  <label>Challenges description FR</label>
                  <MultipleLinesInput
                    onChange={(values) =>
                      updateProject(
                        ["challengesDescriptionParagraphs", "fr"],
                        values
                      )
                    }
                    values={challengesDescriptionParagraphsFr}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div>
                  <label>Challenges description EN</label>
                  <MultipleLinesInput
                    onChange={(values) =>
                      updateProject(
                        ["challengesDescriptionParagraphs", "en"],
                        values
                      )
                    }
                    values={challengesDescriptionParagraphsEn}
                  />
                </div>
              </Col>
            </Row>

            <Divider />

            <h6>Mobile</h6>

            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Hardware images</label>
                <MultipleImagesUpload
                  onChange={(files) => updateProject(["hardwareImages"], files)}
                  files={hardwareImages}
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className="vertical-align-label">
                  <label>Hardware is tablet:</label>
                  <Switch
                    onChange={(checked) =>
                      updateProject(["hardwareIsTablet"], checked)
                    }
                    checked={hardwareIsTablet}
                  />
                </div>
              </Col>
            </Row>

            <Divider />

            <h6>Design</h6>

            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Video url</label>
                <Input
                  value={videoUrl}
                  onChange={(e) => updateProject("videoUrl", e.target.value)}
                  placeholder="videoUrl"
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Color image</label>
                <ImageUpload
                  className="full-width-image-upload"
                  file={colorImage}
                  onChange={(image) => updateProject("colorImage", image)}
                  onDelete={() => {
                    updateProject("colorImage", null);
                    updateProject("colorImageUrl", null);
                  }}
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <label>Color palette description FR</label>
                <Input.TextArea
                  value={colorPaletteDescriptionFr}
                  onChange={(e) =>
                    updateProject(
                      ["colorPaletteDescription", "fr"],
                      e.target.value
                    )
                  }
                  placeholder="Color palette description FR"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <label>Color palette description EN</label>
                <Input.TextArea
                  value={colorPaletteDescriptionEn}
                  onChange={(e) =>
                    updateProject(
                      ["colorPaletteDescription", "en"],
                      e.target.value
                    )
                  }
                  placeholder="Color palette description EN"
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Color codes</label>
                <MultipleColorsPicker
                  onChange={(colors) => updateProject(["colorCodes"], colors)}
                  colors={colorCodes}
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Font images</label>
                <MultipleImagesUpload
                  onChange={(files) => updateProject(["fontImages"], files)}
                  files={fontImages}
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Icon images</label>
                <MultipleImagesUpload
                  onChange={(files) => updateProject(["iconImages"], files)}
                  files={iconImages}
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>App icon image</label>
                <ImageUpload
                  className="full-width-image-upload"
                  file={appIconImage}
                  onChange={(image) => updateProject("appIconImage", image)}
                  onDelete={() => {
                    updateProject("appIconImage", null);
                    updateProject("appIconImageUrl", null);
                  }}
                />
              </Col>
            </Row>

            <Divider />

            <h6>Website</h6>

            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Website image</label>
                <ImageUpload
                  className="full-width-image-upload"
                  file={websiteImage}
                  onChange={(image) => updateProject("websiteImage", image)}
                  onDelete={() => {
                    updateProject("websiteImage", null);
                    updateProject("websiteImageUrl", null);
                  }}
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <label>Website description list FR</label>
                <MultipleLinesInput
                  onChange={(values) =>
                    updateProject(["websiteDescriptionList", "fr"], values)
                  }
                  values={websiteDescriptionListFr}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <label>Website description list EN</label>
                <MultipleLinesInput
                  onChange={(values) =>
                    updateProject(["websiteDescriptionList", "en"], values)
                  }
                  values={websiteDescriptionListEn}
                />
              </Col>
            </Row>

            <Divider />

            <h6>Special</h6>

            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Special image</label>
                <ImageUpload
                  className="full-width-image-upload"
                  file={specialImage}
                  onChange={(image) => updateProject("specialImage", image)}
                  onDelete={() => {
                    updateProject("specialImage", null);
                    updateProject("specialImageUrl", null);
                  }}
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <label>Special title FR</label>
                <Input
                  value={specialTitleFr}
                  onChange={(e) =>
                    updateProject(["specialTitle", "fr"], e.target.value)
                  }
                  placeholder="Special title FR"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <label>Special title EN</label>
                <Input
                  value={specialTitleEn}
                  onChange={(e) =>
                    updateProject(["specialTitle", "en"], e.target.value)
                  }
                  placeholder="Special title EN"
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <label>Special description FR</label>
                <Input.TextArea
                  value={specialDescriptionFr}
                  onChange={(e) =>
                    updateProject(["specialDescription", "fr"], e.target.value)
                  }
                  placeholder="Special description FR"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <label>Special description EN</label>
                <Input.TextArea
                  value={specialDescriptionEn}
                  onChange={(e) =>
                    updateProject(["specialDescription", "en"], e.target.value)
                  }
                  placeholder="Special description EN"
                />
              </Col>
            </Row>

            <Divider />

            <h6>Links</h6>

            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>App store link</label>
                <Input
                  value={appStoreLink}
                  onChange={(e) =>
                    updateProject("appStoreLink", e.target.value)
                  }
                  placeholder="appStoreLink"
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Google play link</label>
                <Input
                  value={googlePlayLink}
                  onChange={(e) =>
                    updateProject("googlePlayLink", e.target.value)
                  }
                  placeholder="googlePlayLink"
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <label>Website link</label>
                <Input
                  value={websiteLink}
                  onChange={(e) => updateProject("websiteLink", e.target.value)}
                  placeholder="websiteLink"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(EditProject);
