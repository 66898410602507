import React from 'react';
import ReactDOM from 'react-dom';
import { toLogin } from './common/routes';
import { Route, Switch, Redirect, BrowserRouter} from 'react-router-dom';
import AppProvider from "./common/app-provider";

//CSS
import "antd/dist/antd.css";
import './style.css';

//Containers
import Login from './containers/login';
import App from './containers/app';

const routing = (
    <AppProvider>
        <BrowserRouter>
            <Switch>
                <Route path={toLogin()} component={Login} />
                <Route path="/" component={App} />
                <Redirect to={toLogin()}/>
            </Switch>
        </BrowserRouter>
    </AppProvider>
);

ReactDOM.render(routing, document.getElementById('root'));



