import * as React from 'react';
import { notification, Upload } from 'antd';
import { FiUpload, FiXCircle } from 'react-icons/fi';

import './style.css';

const DEFAULT_MAX_FILE_SIZE = 1024 * 1024 * 20; // 20MB
const DEFAULT_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

const ImageUpload = ({ onChange, onDelete, file, disabled, className, multiple = false, allowedFileTypes = DEFAULT_FILE_TYPES, maxFileSize = DEFAULT_MAX_FILE_SIZE }) => {

    // State

    const [isDragging, setIsDragging] = React.useState(false);

    // Handlers

    const beforeUpload = (file) => {
        if (!allowedFileTypes.includes(file.type)) {
            notification.error({ message: "Invalid file type" });
            return false;
        }
        if (file.size > maxFileSize) {
            notification.error({ message: "Too big a file" });
            return false;
        }

        const getBase64 = (img, callback) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result));
            reader.readAsDataURL(img);
        };

        getBase64(file, url => {
            file.url = url;
            file.isUpdated = true;
            if (onChange) onChange(file);
        });

        return false;
    };

  // Rendering

  let url = null; 
  if (file && file.url && file.isUpdated) url = file.url;
  if (file && file.url && !file.isUpdated) url = `${file.url}?rand=${Math.random()}`;

  const placeholder = (
    <div className="upload-placeholder">
      <FiUpload/>
      <p>Upload</p>
    </div>
  );

  return (
    <div className={`upload-holder ${className ? className : ''}`}>
      <div
        className={`image-upload ${isDragging ? 'dragging' : ''}`}
        onDragOver={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDrop={() => setIsDragging(false)}
        onDragEnd={() => setIsDragging(false)}>
        <Upload showUploadList={false} beforeUpload={beforeUpload} disabled={disabled} multiple={multiple}>
          {url ? <img src={url} alt="coucou"/> : placeholder}
        </Upload>
      </div>
      { onDelete && <div className="remove-upload-button"><FiXCircle onClick={onDelete}/></div> }
    </div>
  );
};


export default ImageUpload;
