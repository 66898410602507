import React from 'react';
import { Button, Input } from 'antd';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';

import './style.css';

const MultipleLinesInput = ({ values, onChange }) => {
    
    // Handlers

    const onValueChange = (index, value) => {
        values[index] = value;
        onChange(values);
    };

    const removeValueAtIndex = (index) => {
        if (values.length > 1) {
            values.splice(index, 1);
        } else {
            values[0] = "";
        }
        
        onChange(values);
    };

    const addValue = () => {
        onChange([...values, ""]);
    };

    // Rendering

    const renderInput = (value, index) => {
        return (
            <div className="input-holder">
                <Input.TextArea 
                    value={value}
                    onChange={e => onValueChange(index, e.target.value)}/>
                { !(values.length <= 1 && value === "") && <div className="remove-input-button"><FiXCircle onClick={() => removeValueAtIndex(index)}/></div> }
            </div>
        );
    };

    return (
        <div className="multiple-lines-input">
            {values.map(renderInput)}
            <Button type="dashed" onClick={addValue} size="small">
                <FiPlusCircle/>
            </Button>
        </div>
    );
};

export default MultipleLinesInput;