import React from 'react';
import { ImageUpload } from '../';

import './style.css';

const MultipleImagesUpload = ({ files, onChange }) => {

    // Handlers

    const onFileChange = (index, file) => {
        files[index] = file;
        onChange(files);
    };

    const removeFileAtIndex = (index) => {
        files.splice(index, 1);
        onChange(files);
    };

    const addFile = (file) => {
        files.push(file);
        onChange(files);
    };

    // Rendering

    const renderInput = (file, index) => {
        return <ImageUpload className="image-uploader" file={file} onChange={f => onFileChange(index, f)} onDelete={() => removeFileAtIndex(index)}/>;
    };

    return (
        <div className="multiple-images-upload">
            {files.map(renderInput)}
            <ImageUpload className="image-uploader" onChange={addFile} multiple/>
        </div>
    );
};

export default MultipleImagesUpload;