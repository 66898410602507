import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'antd';
import { FiLogOut, FiMenu } from 'react-icons/fi';
import { useAuthToken } from '../../../common/app-provider';
import { toUsers, toProjects, toImages } from '../../../common/routes';

import './style.css';

const NavLink = ({to, children, currentPath}) => {
  const className = currentPath && currentPath.indexOf(to) > -1 ? 'link is-active' : 'link';
  return (
    <li className={className}>
      <Link to={to}>
        {children}
      </Link>
    </li>
  );
};

const Nav = ({ location }) => {

  // State

  const setAuthToken = useAuthToken()[1];
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Handlers

  const closeMenu = () => setMenuOpen(false);
  const toggleMenu = () => {setMenuOpen(!isMenuOpen)};
  const logout = () => setAuthToken(null);
  document.addEventListener('mouseup', closeMenu);
  document.addEventListener("touchend", closeMenu);

  // Rendering

  return (
    <nav className={isMenuOpen ? 'is-open': ''}>

      <Link className="unlink" to={toProjects()}><div className="logo">TB.CMS</div></Link>

      <div className="link-holder">
        <ul>
          <NavLink to={toUsers()} currentPath={location.pathname}>Users</NavLink>
          <NavLink to={toProjects()} currentPath={location.pathname}>Projects</NavLink>
          <NavLink to={toImages()} currentPath={location.pathname}>Images</NavLink>
        </ul>
        
        <FiMenu className="burger-button" onClick={(toggleMenu)}/>

        <Button
          className="logout-button"
          type="default"
          size="small"
          onClick={logout}>
          <span>Logout</span>
          <FiLogOut/>
        </Button>

      </div>

    </nav>
  );
}

export default withRouter(Nav);
