const AUTH_TOKEN_KEY = 'auth_token';

export const storeAuthToken = token => {
    if (!token) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
    } else {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
    }
};
  
export const getStoredAuthToken = () => {
    return localStorage.getItem(AUTH_TOKEN_KEY);
};
  