import React, { useEffect, useState } from 'react';
import { Button, Modal, Table , Divider, Card, Tag, Icon, notification, Row, Col } from 'antd';
import Axios from 'axios';
import ReactDragListView from "react-drag-listview";
import { isMobile } from "react-device-detect";
import { Link, withRouter } from 'react-router-dom';
import { toEditImage } from '../../../common/routes';

const Images = () => {

  // State

  const [images, setImages] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  
  // Effects

  useEffect(() => {
    fetchImages();
  }, []);

  // Network 

  const fetchImages = async () => {
    try {
      setIsLoading(true);
      const { data } = await Axios.get("/images");
      setImages(data);
      setIsLoading(false);
    } catch (error) {
    } 
  };

  const deleteImage = async (image) => {
    const index = images.indexOf(image);
    images[index].isDeleting = true;
    setImages([...images]);

    try {
      await Axios.delete(`/images/${image._id}`);
      await fetchImages();
      notification.success({ message: `${image.title} deleted` });
    } catch (error) {
      images[index].isDeleting = false;
      setImages([...images]);
    }  
  };

  const updateImageOrder = async (fromIndex, toIndex) => {
    try {
      setIsLoading(true);
      await Axios.put("/images", {fromIndex, toIndex});
      await fetchImages();
      setIsLoading(false);
    } catch (error) {
    } 
  };

  // Handlers

  const onDeleteImage = (image) => {
    Modal.confirm({
      title: 'Delete project',
      content: `Are you sure you wish to delete ${image.title}?`,
      okText: 'Ok',
      onOk: () => deleteImage(image),
      cancelText: 'Cancel',
    });
  }

  const onDragEnd = async (fromIndex, toIndex) => {
    setIsLoading(true);
    await updateImageOrder(fromIndex, toIndex);
    setIsLoading(false);
  }

  // Rendering

  const { Column } = Table;

  const extra = <Link to={toEditImage('new')}><Button type="primary">Create</Button></Link>;

  const renderPublishedColumn = (image) => <Tag color={image.published ? "green" : "blue"}>{image.published ? "Published" : "Not Published"}</Tag>;
  
  const renderActionsColumn = (image) => {
    return (
      <span>
        <Link to={toEditImage(image._id)}>
          <Button disabled={image.isDeleting}>Edit</Button>
        </Link>
        <Divider type="vertical" />
        <Button loading={image.isDeleting} onClick={() => onDeleteImage(image)}>Delete</Button>
      </span>
    );
  };

  const renderMoveColumn = (proj, record, idx) => {
    return (
      <div>
        {idx > 0 ? <Icon type="caret-up" onClick = {async () => await onDragEnd(idx, idx - 1)}/> : null}
        {idx < images.length - 1 ? <Icon type="caret-down" onClick = {async () => await onDragEnd(idx, idx + 1)}/> : null}
      </div>
    );
  }
  
  return (
    <Row justify="center" type="flex">
      <Col xs={24} sm={24} md={24} lg={18}> 
        <Card 
          title="Projects"
          extra={extra} >  
          <ReactDragListView handleSelector="tr" onDragEnd={onDragEnd} >  
            <Table bordered loading={isLoading} dataSource={images} pagination={false} style={{cursor: "move"}}>
              <Column title="Title" render={(image) => image.title.en} />
              <Column 
                title="Published" 
                render={renderPublishedColumn} />
              <Column
                title="Actions"
                key="action"
                render={renderActionsColumn} />
              { isMobile &&
                <Column
                  title="Move"
                  key="move"
                  render={renderMoveColumn} />
              }
            </Table>
          </ReactDragListView>  
        </Card>
      </Col>
    </Row>
  );
}

export default withRouter(Images);