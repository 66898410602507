import React from 'react';
import Axios from 'axios';
import { Card, Input, Button, Form, Col, Row, notification } from 'antd';
import { Redirect } from 'react-router-dom';
import { useAuthToken } from '../../common/app-provider';

import './style.css';

const Login = () => {

  // State

  const [ authToken, setAuthToken ] = useAuthToken();
  const [formValues, setFormValues] = React.useState({
    username: '',
    password: ''
  });
  const [isLoading, setIsLoading] = React.useState(false);

  // Network 

  const login = async () => {
    setIsLoading(true)

    try {
      const { data } = await Axios.post("/users/login", formValues);
      const { token, username } = data;
      notification.success({ message: `Hello coucou ${username}` });
      setAuthToken(token);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  // Rendering

  const { username, password } = formValues;
  const loginEnabled = username.length >= 4 && password.length >= 4;

  // If user is logged in, redirect to app
  if (authToken) return <Redirect to="/" />;

  return (
    <Row>
      <Col lg={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
        <div id="login-container">
          <Form onSubmit = {login} >    
            <Card title={"Login"} type={"primary"} >
                <Input
                  name="email"
                  value={username}
                  onChange={e => setFormValues({ ...formValues, username: e.target.value })}
                  onPressEnter={login}
                  style={{ marginBottom: "10px"}}
                  placeholder={"Username"} />
                <Input.Password
                  name="password"
                  value={password}
                  onChange={e => setFormValues({ ...formValues, password: e.target.value })}
                  onPressEnter={login}
                  placeholder={"Password"} />       
                <Button 
                  style={{ marginTop: "10px", float: 'right'}} 
                  disabled={!loginEnabled} 
                  loading={isLoading}
                  onClick={login}>
                  Login
                </Button>
            </Card>
          </Form>
        </div>
      </Col>
    </Row> 
  );
};

const WrappedLogin = Form.create({ name: 'login' })(Login);
export default WrappedLogin;