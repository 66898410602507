import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Input, Card, Button, Modal, notification, Table, Row, Col } from 'antd';

//CSS
import './style.css';

const initialFormValues = {
  username: '',
  password: ''
};

const Users = () => {

  // State 

  const [users, setUsers] = useState(null);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [isCreatingUser, setIsCreatingUser] = useState(false);

  // Effects

  useEffect(() => {
    fetchUsers();
  }, []);

  // Handlers

  const onDeleteUserClick = (user) => {
    Modal.confirm({
      title: 'Delete user',
      content: `Are you sure you wish to delete ${user.username}?`,
      okText: 'Ok',
      onOk: () => deleteUser(user),
      cancelText: 'Cancel',
    });
  };

  // Network

  const fetchUsers = async () => {
    try {
      const { data } = await Axios.get("/users");
      setUsers(data);
    } catch (error) {
    } 
  };

  const createUser = async () => {
    setIsCreatingUser(true);
    try {
      await Axios.post("/users", formValues);
      await fetchUsers();
      setFormValues(initialFormValues)
      notification.success({ message: `${formValues.username} created` });
    } catch (error) {
    } finally {
      setIsCreatingUser(false);
    }
  };

  const deleteUser = async (user) => {
    const index = users.indexOf(user);
    users[index].isDeleting = true;
    setUsers([...users]);

    try {
      await Axios.delete(`/users/${user._id}`);
      await fetchUsers();
      notification.success({ message: `${user.username} deleted` });
    } catch (error) {
      users[index].isDeleting = false;
      setUsers([...users]);
    } 
  };

  // Rendering

  const { Column } = Table;

  const { username, password } = formValues;

  const isCreatingUserEnable = username.length >= 4 && password.length >= 4;

  return (
    <Row justify="center" type="flex">
      <Col xs={24} sm={24} md={24} lg={18}> 
        <Card title="Users">   

          <div className="add-user-form">
            <Input
              value={username}
              disabled={isCreatingUser}
              onChange={e => setFormValues({ ...formValues, username: e.target.value })}
              onPressEnter = {createUser}
              placeholder="username" />
            <Input.Password
              value={password}
              disabled={isCreatingUser}
              onChange={e => setFormValues({ ...formValues, password: e.target.value })}
              onPressEnter = {createUser}
              placeholder="password" />
            <Button 
              type="primary" 
              disabled={!isCreatingUserEnable}
              onClick={createUser}
              loading={isCreatingUser}>
                Add
            </Button>
          </div>         
            
          <Table dataSource={users} loading={!users} bordered>
            <Column title="Username" dataIndex="username" key="username" />
            <Column title="Date de création" dataIndex="createdAt" key="createdAt" />
            <Column
              title="Actions"
              key="action"
              render={(text, user) => <Button type="default" loading={user.isDeleting} disabled={false} onClick={() => onDeleteUserClick(user)}>delete</Button>} />
          </Table>

        </Card>
      </Col>
    </Row>
  );
}

export default Users;
