import React from 'react';
import { Col, Row } from 'antd';
import { useScroll, useWindowSize } from "../../common/hooks";

import './style.css';

const MOBILE_WINDOW_SIZE = 750;
const DESKTOP_PADDING = 30;
const MOBILE_PADDING = 10;

const StickyCardHeader = ({ title, extra }) => {

    // Hooks

    const { scrollY } = useScroll();
    const { width } = useWindowSize();

    // Rendering

    const padding = width > MOBILE_WINDOW_SIZE ? DESKTOP_PADDING : MOBILE_PADDING;
    const showHeader = scrollY >= padding;

    return (
        <div className="sticky-card-header-container" style={{"display": showHeader ? "block" : "none" }}>
            <Row justify="center" type="flex">
                <Col xs={24} sm={24} md={24} lg={18}> 
                    
                    <div className="sticky-card-header">
                        <div className="sticky-card-header-tile">
                            {title}
                        </div>
                        <div className="sticky-card-header-extra">
                            {extra}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default StickyCardHeader;