import React from 'react';
import { Button } from 'antd';
import { SketchPicker } from 'react-color';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';

import './style.css';

const MultipleColorsPicker = ({ colors, onChange }) => {
    
    // Handlers

    const onColorChange = (index, color) => {
        colors[index] = color;
        onChange(colors);
    };

    const removeColorAtIndex = (index) => {
        colors.splice(index, 1);
        onChange(colors);
    };

    const addColor = () => {
        onChange([...colors, { hex: null }]);
    };

    // Rendering

    const renderPicker = (color, index) => {
        return (
            <div className="picker-holder">
                <SketchPicker
                    color={color}
                    onChange={({ hex }) => onColorChange(index, hex)}/>
                <div className="remove-picker-button"><FiXCircle onClick={() => removeColorAtIndex(index)}/></div>
            </div>
        );
    };

    return (
        <div className="multiple-colors-picker">
            {colors.map(renderPicker)}
            <div className="picker-holder">
                <Button type="dashed" onClick={addColor} size="small">
                    <div><FiPlusCircle/></div>
                </Button>
            </div>
        </div>
    );
};

export default MultipleColorsPicker;