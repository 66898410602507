import React from 'react';
import { get } from 'lodash';
import Axios from 'axios';
import { notification } from 'antd';
import { storeAuthToken, getStoredAuthToken } from './local-storage';

// Configure axios
Axios.defaults.baseURL = process.env.REACT_APP_API_URL || "http://localhost:9000/api";
Axios.defaults.headers['x-access-token'] = getStoredAuthToken();

// Configure app context
const AppContext = React.createContext({
    authToken: getStoredAuthToken()
});

/**
 * Custom hook to get and set the auth token
 */
export const useAuthToken = () => {
    const { authToken, setAuthToken } = React.useContext(AppContext);
    return [authToken, setAuthToken];
};

const AppProvider = ({ children }) => {

    // States and initial values 

    const [authToken, setAuthToken] = React.useState(getStoredAuthToken());

    /**
     * Set configuration for axios
     */

    // HACK: use layout effect should not be used here
    React.useLayoutEffect(() => {

        // Add token and locale to axios calls if defined
        const requestInterceptor = Axios.interceptors.request.use((config) => {
            config.headers['x-access-token'] = authToken;
            return config;
        }, error => Promise.reject(error));

            // Add response interceptor to show error message, or disconnect user if forbidden
            const responseInterceptor = Axios.interceptors.response.use(res => res, error => {
            const errorStatus = get(error, ['response', 'data', 'status'], 400);
            const errorMsg = get(error, ['response', 'data', 'message'], "Oups! Something went wrong");

            // If user gets a forbidden error, log him out
            if (errorStatus === 403) setAuthToken(null);

            // Notify the user of the error message
            notification.error({ message: errorMsg });

            return Promise.reject(error);
        });

        return () => {
            Axios.interceptors.request.eject(requestInterceptor);
            Axios.interceptors.response.eject(responseInterceptor);
        };

    }, [authToken]);

    /**
     * Update local storage on token update
     */
    React.useEffect(() => {
        storeAuthToken(authToken);
    }, [authToken]);

    return (
        <AppContext.Provider value={{ authToken, setAuthToken }} >
            {children}
        </AppContext.Provider>
    );
    
}

export default AppProvider;